<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="text-center mb-4">
        <h2 class="subtitle secondary--text">Lista de Productos</h2>
      </v-col>
    </v-row>
    <v-row>
      <!-- Recorre los productos y crea una tarjeta para cada uno -->
      <v-col
        v-for="product in products"
        :key="product.name"
        cols="12"
        sm="6"
        md="4"
        lg="3"
        class="mb-4"
      >
        <v-card
          class="product-card mx-auto"
          elevation="3"
          hover
          @click="viewProduct(product)"
        >
          <v-img
            :src="'https://via.placeholder.com/200x100'"
            height="100"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
          >
            <v-card-title class="subtitle white--text">{{ product.name }}</v-card-title>
          </v-img>

          <v-card-subtitle class="pb-0">
            <span class="black--text">{{ product.description | truncate(60) }}</span>
            <br/>
            <span class="caption">{{ product.category }}  </span>
          </v-card-subtitle>

          <v-divider class="mx-4"></v-divider>

          <v-card-actions>
            <v-btn color="primary" text>
              ${{ product.salePrice }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-btn
      fab
      bottom
      right
      color="secondary"
      @click="goToAddProduct"
      class="v-btn--fixed"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { useProductStore } from "@/stores/productStore";

export default {
  name: "ProductList",
  computed: {
    products() {
      const productStore = useProductStore();
      return productStore.products;
    },
  },
  methods: {
    viewProduct(product) {
      const productStore = useProductStore();
      productStore.setEditingProduct(product); // Establecer el producto en edición
      this.$router.push('/product'); // Redirige a la vista de producto
    },
    goToAddProduct() {
      this.$router.push("/product"); // Redirige a la ruta de agregar producto
    },
  },
};
</script>

<style scoped>
.v-card {
  min-height: 200px;
}
</style>
